// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import Chartkick from "@ockhamlabsinc/chartkick"
import Highcharts from "highcharts"
import * as ace from 'brace';
import 'brace/mode/sql';
import 'brace/theme/monokai';

Chartkick.use(Highcharts)

require('highcharts/modules/funnel')(Highcharts);
require('highcharts/modules/wordcloud')(Highcharts);
require('highcharts/modules/sunburst')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require("packs/blazer");
require("packs/custom");
require('highcharts-grouped-categories/grouped-categories')(Highcharts);

// Sentinel - CloudQuery client dependencies
import "core-js/stable"
import "regenerator-runtime/runtime"

Chartkick.options = {
  colors: ["#1D3557", "#AE2012", "#EE9B00", "#CA6702", "#005F73"]
}

Rails.start()
// Turbolinks.start()
ActiveStorage.start()
